import Vue from 'vue'
// import $ from 'jquery'
import axios from "axios"
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)


export default {
    components: {

    },
    data: function() {
        return {
            pageTitle: this.$t('import.page_titre'),
            file: null,
            file_import: "",
            progress: 0,
            show_progress: false,
            articles: [],
        }
    },
    methods: {

        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.BASE_URL + "/articles/importaction";
                        var formData = new FormData();
                        formData.append('filename', that.file_import);

                        axios.post(link, formData).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.file = null;
                                that.file_import = "";
                                that.articles = [];
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        uploadFile(event) {
            this.show_progress = true;
            const URL = this.BASE_URL + '/articles/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            // var files = event.target.files || event.dataTransfer.files;


            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                    this.progress = percentCompleted;
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('upload response > ', response);
                    that.show_progress = false;
                    that.progress = 0;
                    that.file_import = response.data.upload_data.file_name;
                    that.showPreview(that.file_import);
                }
            ).catch(error => {
                console.log(error);
            })
        },
        showPreview(filename) {
            axios.get(this.BASE_URL + "/articles/showpreview", {
                params: { filename: filename }
            }).then(response => {

                this.articles = response.data;
                console.log(this.articles);
            });
        }


    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        console.log(this.$parent.role_user);


    },
    watch: {

    },
    mounted: function() {
        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

    }
}